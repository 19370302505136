<template>
  <SettingsWrapper />
</template>

<script>
import SettingsWrapper from '@/components/settings/SettingsWrapper'

export default {
  name: 'Settings',
  components: {
    SettingsWrapper
  }
}
</script>
