<template>
  <v-container
    fluid
  >
    <Subscriptions />

    <v-expansion-panels
      v-model="openPanels"
      class="ma-0 pa-0 mt-4"
      multiple
    >
      <v-expansion-panel
        v-for="(item, idx) in items"
        :key="idx"
      >
        <v-expansion-panel-header
          :class="item.color"
        >
          {{ item.label | translate }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <component
            :is="item.component"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import Subscriptions from './Subscriptions'
import BillingData from './BillingData'

export default {
  name: 'Profile',
  components: {
    Subscriptions,
    BillingData
  },
  data () {
    return {
      openPanels: [],
      items: [
        { label: '$l.settings.billingData', component: 'BillingData' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep {.v-expansion-panel-content__wrap {
    padding: 0px !important;

  }}
</style>
