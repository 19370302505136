<template>
  <basic-card
    title="$l.settings.paymentManagement"
    class="mt-9"
  >
    <v-row
      dense
    >
      <v-col>
        <v-card-title
          class="body-1 pb-2 pt-0"
        >
          {{ $i18n.t('$l.settings.paymentManagementInfo') }}
          <div
            v-if="customerPortal !== null"
            class="body-2 error--text mt-2"
          >
            {{ $i18n.t('$l.settings.paymentManagementWarning') }}
          </div>
        </v-card-title>

        <app-api-call
          class="ma-0 mx-3 mt-3"
          :call-result="callResult"
        />

        <v-card-actions
          class="mt-0"
        >
          <v-btn
            v-if="customerPortal !== null"
            class="primary"
            outlined
            rounded
          >
            <a
              class="white--text body-1 font-weight-medium"
              style="text-decoration: none;"
              :href="customerPortal.url"
              target="_blank"
            >
              {{ $i18n.t('$l.settings.paymentManagementPortalLink') }}
            </a>
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </basic-card>
</template>

<script>
export default {
  name: 'Subscriptions',
  data () {
    return {
      callResult: { finished: false, authorized: false, error: null, info: null },
      customerPortal: null
    }
  },
  mounted () {
    this.getCustomerPortal()
  },
  methods: {
    getCustomerPortal () {
      this.$xapi.get('endpointsStripeManagement/subscriptions')
        .then(r => {
          this.customerPortal = r.data
        })
        .catch(e => {
          this.callResult.error = this.$i18n.t('$l.settings.paymentManagementPortalLinkError')
        })
        .finally(() => {
          this.callResult.finished = true
        })
    }
  }
}
</script>
